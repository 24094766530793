<template>
    <div>
        <div class="columns is-mobile is-multiline">
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Name') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('Name')" v-model="programs.name">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Point Value') }}</label>
                    <div class="control">
                        <input class="input" type="number"
                               :placeholder="i18n('Point Value')" v-model="programs.pointValue">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Status') }}</label>
                    <div class="control">
                        <div class="select">
                            <select v-model="programs.status">
                                <option value="1">
                                    {{ i18n('Closed') }}
                                </option>
                                <option value="0">
                                    {{ i18n('Open') }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Maximum number of winners') }}</label>
                    <div class="control">
                        <input class="input" type="number" v-model="programs.max_winners"
                               :disabled="programs.status !== '1'">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Choose an File') }}</label>
                    <div class="control">
                        <label class="file-label">
                            <input class="file-input" type="file"
                                   accept=".jpg, .png, .webp" @change="handleFileUpload">
                            <span class="file-cta">
                                <span class="file-label">
                                    {{ i18n('Choose an File') }}...
                                </span>
                            </span>
                            <span class="file-name">
                                {{ fileName || i18n('No file chosen') }}
                            </span>
                        </label>
                        <p class="letterFileSize">
                            {{ i18n('Maximum file size') }}: 16 MB.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <a class="is-full-mobile is-half-tablet button-alignment"
           @click="$router.push('/programs')">
            <button class="button butt-return">
                {{ i18n('Back') }}
                <fa class="i-icon" icon="arrow-left"/>
            </button>
        </a>
        <a class="is-full-mobile is-half-tablet button-alignment"
           @click="fetchUpdate">
            <button class="button butt-view">
                {{ i18n('Update') }}
                <fa class="i-icon" icon="check"/>
            </button>
        </a>
        <a class="is-full-mobile is-half-tablet button-alignment"
           @click="fetchDelete">
            <button class="button butt-delete">
                {{ i18n('Delete') }}
                <fa class="i-icon" icon="trash-alt"/>
            </button>
        </a>
    </div>
</template>

<script>
import { FontAwesomeIcon as Fa } from '@fortawesome/vue-fontawesome';

export default {
    name: 'Edit',

    components: { Fa },

    inject: ['http', 'i18n', 'errorHandler', 'route'],

    data: () => ({
        programs: {
            name: '',
            pointValue: '',
            status: '',
            max_winners: '',
            file: '',
        },
        imgBase64: null,
        fileName: '',
    }),

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.http.get(this.route('programs.show', this.$route.params.programs))
                .then(({ data }) => {
                    this.programs = data.programs;
                    this.imgBase64 = `data:${this.programs.imageType};base64, ${this.programs.content}`;
                }).catch(this.errorHandler);
        },
        fetchDelete() {
            this.http.delete(this.route('programs.destroy', this.$route.params.programs))
                .then(() => {
                    this.$router.push('/programs');
                }).catch(this.errorHandler);
        },
        handleFileUpload(event) {
            // eslint-disable-next-line prefer-destructuring
            this.programs.file = event.target.files[0];
            this.fileName = event.target.files[0].name;
        },
        fetchUpdate() {
            const formData = new FormData();
            formData.append('name', this.programs.name);
            formData.append('file', this.programs.file);
            formData.append('id', this.programs.id);
            formData.append('pointValue', this.programs.pointValue);
            formData.append('status', this.programs.status);
            const maxWinnersValue = this.programs.max_winners !== null ? this.programs.max_winners : '';
            formData.append('max_winners', maxWinnersValue);
            const headers = { 'Content-Type': 'multipart/form-data' };
            this.http.post(this.route('programs.update'), formData,
                { headers })
                .then(() => {
                    this.$router.push('/programs');
                }).catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss">
@import '../../../sass/enso.scss';

.butt-return{
    background-color: $orange;
    border-color: transparent;
    color: $secondary !important;
}

.i-icon {
    margin-left: 5px;
}

.butt-delete{
    background-color: $red;
    border-color: transparent;
    color: $secondary !important;
}

.butt-view{
    background-color: $green;
    border-color: transparent;
    color: $secondary !important;
}

.button-alignment{
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 40px;
}

.styleInput{
    background-color: transparent;
    border-color: transparent;
}

.letterFileSize{
    font-size: small;
    color: $greyToneEleven;
}

</style>
